<template>
  <div class="container">
    <div id="map" class="map" :style="`height: ${mapHeight}px`"></div>
  </div>
</template>

<script>
import L from "leaflet"
import { GestureHandling } from "leaflet-gesture-handling"
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";


export default {
  name: 'Map',
  props: ['lat', 'lon', 'radius', 'showUserLocation', 'items', 'teamPositions', 'challenge', 'challenges', 'zoom', 'mapHeight'],
  mounted(){
    this.initMap()
  },
  methods: {
    initMap() {
      const location = [this.lat, this.lon]
      L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)
      this.map = L.map('map', {
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: 'bottomright'
        },
        maxZoom: 25,
        gestureHandling: true
      }).setView(location, this.zoom ? this.zoom : 18)
      this.map.on('zoomend', () => {
        this.$store.commit('updateTaskMapZoom', this.map.getZoom())
      })
      this.tileLayer = L.tileLayer(
        'https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=V2XqbAbuAC6W5SqSo3tQXBESfHSlqC5Q',
        {
          // attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
          maxZoom: 25,
          maxNativeZoom: 19
       }
      )
      const locationIcon = L.divIcon({
        html: '<div class="container has-text-centered"><i class="fas fa-map-marker-alt fa-3x" style="color: #183153"></i><div class="is-size-6 map-position-modifier has-text-weight-bold" style="width: 300px; text-align: left;">My GPS location</div></div>',
        iconSize: [27, 36],
        iconAnchor: [13.5, 36],
        className: 'myDivIcon'
      })
      L.marker(
        location,
        { icon: locationIcon }
      ).addTo(this.map)
      // const marker = L.marker(location, {draggable: false}).addTo(this.map)

      if(this.radius){
        L.circle(location, this.radius).addTo(this.map)
      }
      if(this.showUserLocation){
        const myIcon = L.divIcon({
          html: '<i class="fas fa-child fa-3x" style="color: #fc0303"></i>',
          iconSize: [20, 20],
          className: 'myDivIcon'
        })
        L.marker(
          [this.$store.state.userLocation.lat, this.$store.state.userLocation.lon],
          { icon: myIcon }
        ).addTo(this.map)
      }
      if(this.items && this.items.length > 0){
        const questionMarkIcon = L.divIcon({
          html: '<i class="fas fa-question fa-2x" style="color: #fc0303"></i>',
          iconSize: [20, 20],
          className: 'myDivIcon'
        })
        this.items.forEach(item => {
          L.marker(
            [item.position.geopoint.oa, item.position.geopoint.ha],
            { icon: questionMarkIcon }
          ).addTo(this.map)
        })
      }
      let challenges = this.challenges
      if(this.challenge){
        challenges = [this.challenge]
      }
      if(challenges && challenges.length > 0){
        let firstIncompleteChallengeIndex = 0
        challenges.forEach((challenge, index) => {
          if(challenge.completed){
            firstIncompleteChallengeIndex += 1
          }
          if (
            challenge.position &&
            challenge.position.shownOnMap &&
            (
              !this.$store.state.adventureTeam.stageDetails.complete_challenges_in_order ||
              !(
                this.$store.state.adventureTeam.stageDetails.complete_challenges_in_order &&
                !challenge.completed &&
                firstIncompleteChallengeIndex !== index
              )
            ) &&
            !(
              this.$store.state.adventureTeam.hiddenTaskCategories &&
              challenge.task_category &&
              this.$store.state.adventureTeam.hiddenTaskCategories.includes(
                challenge.task_category
              )
            )
          ) {
            const colourCode = challenge.completed ? '20DC19' : 'ffb200'
            let challengeName = `${challenge.name}`
            if(!this.$store.state.uiMods.hidePointsStarsWording && challenge.points > 0){
              challengeName += ` (${challenge.points} pt${challenge.points === 1 ? '' : 's'})`
            }
            if (
              challenge.max_completions_per_session &&
              challenge.completionsLeft > 0
            ) {
              challengeName += ` | Limited slots: ${challenge.completionsLeft} of ${challenge.max_completions_per_session} left`
            } else if (
              challenge.max_completions_per_session &&
              challenge.completionsLeft === 0
            ) {
              challengeName += ` | Closed, no slots left`
            }
            let questionMarkIcon
            if (challenge.position.mapIconUrl) {
              questionMarkIcon = L.divIcon({
                html: `<div class="is-flex is-align-items-center" style="width: 500px">
                        <img src="${challenge.position.mapIconUrl}" style="width: 36px; height: 36px; object-fit: contain;" class="mr-2"/>
                        <div class="is-size-6 map-position-modifier has-text-weight-bold" style="width: 500px; text-align: left;">${challengeName}</div>
                      </div>`,
                iconSize: [36, 36],
                className: 'myDivIcon'
              })
            } else {
              // Default icon with Font Awesome
              questionMarkIcon = L.divIcon({
                html: `<div class="is-flex" style="width: 500px">
                        <i class="fas ${challenge.completed ? 'fa-check' : 'fa-times'} fa-1x mr-2" style="color: #${colourCode}"></i>
                        <div class="is-size-6 map-position-modifier has-text-weight-bold" style="width: 500px; text-align: left;">${challengeName}</div>
                      </div>`,
                iconSize: [8.25, 12],
                className: 'myDivIcon'
              })
            }
            L.marker(
              [challenge.position.lat, challenge.position.lon],
              { icon: questionMarkIcon }
            )
            .addTo(this.map)
            // .on('click', () => {
            //   this.goToTask(index)
            // })
            if(!challenge.completed){
              const taskCircle = L.circle(challenge.position, challenge.position.radius_in_meters).addTo(this.map)
              taskCircle.setStyle({
                color: challenge.completed ? 'green' : '#ffc338',
                weight: 1
              })
            }
          }

          if (challenge.type === 'direction') {
            const colourCode = 'ffb200'
            let challengeName = `${challenge.name.slice(0, 10)}${challenge.name.length > 10 ? '...' : ''}`
            const distance = this.distanceBetweenTwoGeoCoordinatesInMeters(
              challenge.answers[0].lat, challenge.answers[0].lon, this.lat, this.lon
            )
            const distanceDisplay = distance > 1000 ? `${(distance / 1000).toFixed(1)}km` : `${distance.toFixed(0)}m`;
            challengeName += ` (${distanceDisplay} away)`
            const questionMarkIcon = L.divIcon({
              html: `<div class="is-flex" style="width: 500px"><i class="fas ${challenge.completed ? 'fa-check' : 'fa-times'} fa-1x mr-2" style="color: #${colourCode}"></i><div class="is-size-6 map-position-modifier has-text-weight-bold" style="width: 500px; text-align: left;">${challengeName}</div></div>`,
              iconSize: [8.25, 12],
              className: 'myDivIcon'
            })
            L.marker(
              [challenge.answers[0].lat, challenge.answers[0].lon],
              { icon: questionMarkIcon }
            )
            .addTo(this.map)
            const taskCircle = L.circle([challenge.answers[0].lat, challenge.answers[0].lon], challenge.answers[0].radius_in_meters).addTo(this.map)
            taskCircle.setStyle({
              color: challenge.completed ? 'green' : '#ffc338',
              weight: 1
            })
          }

        })
      }
      // add previous location
      if(this.teamPositions && this.teamPositions.length > 0){
        const myTeamPositions = this.teamPositions.filter((teamPosition) => {
          return teamPosition.teamName === this.$store.state.adventureTeam.teamName
        })
        if(myTeamPositions.length > 1){
          const prevPosition = myTeamPositions[1]
          if(
            this.distanceBetweenTwoGeoCoordinatesInMeters(
              prevPosition.lat, prevPosition.lon, this.lat, this.lon
            ) > 20
          ){
            const prevLocationIcon = L.divIcon({
              html: '<div class="container has-text-centered"><i class="fas fa-map-marker-alt fa-2x" style="color: #9e9d9d"></i><div class="is-size-6 map-position-modifier has-text-weight-bold" style="width: 300px; text-align: left; color: #9e9d9d;">Prev. location</div></div>',
              iconSize: [27, 36],
              iconAnchor: [13.5, 36],
              className: 'myDivIcon'
            })
            L.marker(
              [prevPosition.lat, prevPosition.lon],
              { icon: prevLocationIcon }
            ).addTo(this.map)
          }
        }
      }
      if(
        this.teamPositions && this.teamPositions.length > 0 &&
        this.$store.state.adventureTeam.allowTeamPositionsView
      ){
        this.teamPositions.filter((v,i,a) => a.findIndex(v2=>(v2.teamName===v.teamName))===i)
        .filter((teamPosition) => {
          return teamPosition.teamName !== this.$store.state.adventureTeam.teamName
        })
        .forEach(teamPosition => {
          const updatedOnString = new Date(teamPosition.updatedOn.seconds * 1000).toLocaleTimeString()
          const myIcon = L.divIcon({
            html: `<div><i class="fas fa-street-view fa-2x" style="color: #fc0303"></i><div class="is-size-7 map-position-modifier">${teamPosition.teamName} <div class="has-text-weight-bold is-size-7">${updatedOnString}</div></div></div>`,
            iconSize: [20, 20],
            className: 'myDivIcon'
          })
          L.marker(
            [teamPosition.lat, teamPosition.lon],
            { icon: myIcon }
          ).addTo(this.map)
        })
      }
      this.tileLayer.addTo(this.map)
    },
    openGoogleMapsDirections (lat, lon) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lon}`)
    },
    goToTask (challengeIndex) {
      this.$store.dispatch('openTaskFromMap', challengeIndex)
    },
    distanceBetweenTwoGeoCoordinatesInMeters(lat1, lon1, lat2, lon2) {
      if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0
      }
      else {
        const radlat1 = Math.PI * lat1/180
        const radlat2 = Math.PI * lat2/180
        const theta = lon1-lon2
        const radtheta = Math.PI * theta/180
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
          dist = 1
        }
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        dist = dist * 1.609344
        return dist*1000
      }
    }
  }
}
</script>

<style>
.map {
  height: 320px;
  z-index: 0 !important;
}

.myDivIcon {
  text-align: center;
  line-height: 20px;
}

.map-position-modifier {
  width: 75px;
}

.fullscreen-icon:before {
  font-family: 'Font Awesome 5 Free';
  font-size: medium;
  content: '\f065';
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
}
</style>
